import { render, staticRenderFns } from "./Tourism.vue?vue&type=template&id=1352af40&scoped=true&"
import script from "./Tourism.vue?vue&type=script&lang=js&"
export * from "./Tourism.vue?vue&type=script&lang=js&"
import style0 from "./Tourism.vue?vue&type=style&index=0&id=1352af40&prod&lang=scss&"
import style1 from "./Tourism.vue?vue&type=style&index=1&id=1352af40&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1352af40",
  null
  
)

export default component.exports